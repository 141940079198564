import { Controller } from '@hotwired/stimulus'
import { toggleBodyLock } from 'scripts/utils/application'

export default class extends Controller {
  static targets = ['list', 'mainBar', 'menu']
  static values = { big_push: Boolean }

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.config = {
      isOpenClass: 'is-open',
    }
  }

  connect() {
    if (this.bigPushValue) {
      this.handleDisplayHeaderIfBigPush()
    }
  }

  disconnect() {
    if (this.scrollFrame) {
      cancelAnimationFrame(this.scrollFrame)
    }
  }

  /* ----- ACTION EVENTS ----- */

  handleToggleMenu(event) {
    this.toggleMenu(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */

  toggleMenu(trigger, force) {
    if (this.hasMenuTarget) {
      toggleBodyLock(force)
      event.currentTarget.classList.toggle(this.config.isOpenClass, force)
      this.menuTarget.classList.toggle(this.config.isOpenClass, force)
    }
  }

  handleDisplayHeaderIfBigPush() {
    const focusBlock = document.querySelector('.section-wrapper-focus')
    const heightFocusBlock = focusBlock ? focusBlock.getBoundingClientRect().height : 0

    const scroll = () => {
      if (window.scrollY >= heightFocusBlock ) {
        this.element.classList.remove('page-header--big-push')
      } else {
        this.element.classList.add('page-header--big-push')
      }
      this.scrollFrame = requestAnimationFrame(scroll)
    }

    this.scrollFrame = requestAnimationFrame(scroll)
  }
  /* ----- GETTERS / SETTERS ----- */
}
