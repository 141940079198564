import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'

let inProgress = 0

export default class extends Controller {
  static targets = ['input', 'name', 'check', 'spinner']

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.config = {
      isOpenClass: 'is-open',
      defaultMessage: 'Aucun fichier selectionné'
    }
  }

  connect() {
    this.truncateNameOnLoad()
  }

  /* ----- ACTION EVENTS ----- */

  handleClickInput() {
    this.activeInputFile()
  }

  handleFile() {
    this.updateName()

    Array.from(this.inputTarget.files).forEach(file =>
      this.uploadFile(file),
    )
    this.inputTarget.value = null

    inProgress++

    this.checkTarget.classList.add('is-hidden')
    this.spinnerTarget.querySelector('span').innerHTML = 0
    this.spinnerTarget.classList.remove('is-hidden')

    this.inputTarget.closest('form').querySelector('[type=submit]').classList.add('main-button--disabled')
  }

  /* ----- FUNCTIONS ----- */

  activeInputFile() {
    this.inputTarget.click();
  }

  updateName() {
    const selectedFile = this.inputTarget.files[0];
    this.nameTarget.textContent = this.truncate(selectedFile.name, 15)
    this.nameTarget.classList.add('is-filled')
  }

  truncate(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  uploadFile(file) {
    const url = this.inputTarget.dataset.directUploadUrl
    const upload = new DirectUpload(file, url, this)

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        const elmt = document.createElement('input')
        this.inputTarget.parentNode.appendChild(elmt)
        elmt.setAttribute('type', 'hidden')
        elmt.setAttribute('value', blob.signed_id)
        elmt.name = this.inputTarget.name

        this.spinnerTarget.classList.add('is-hidden')
        this.checkTarget.classList.remove('is-hidden')

        inProgress--

        if (inProgress === 0) {
          this.inputTarget.closest('form').querySelector('[type=submit]').classList.remove('main-button--disabled')
        }
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    // console.log(Math.round((event.loaded*100)/event.total))
    this.spinnerTarget.querySelector('span').innerHTML = Math.round((event.loaded*100)/event.total)
    // Use event.loaded and event.total to update the progress bar
  }

  truncateNameOnLoad() {
    if (this.nameTarget.textContent != this.config.defaultMessage) {
      this.nameTarget.textContent = this.truncate(this.nameTarget.textContent, 15)
    }
  }

  /* ----- GETTERS / SETTERS ----- */
}
