import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['range', 'price', 'submit', 'image']
  static values = { type: String }

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    if (this.typeValue) {
      if (this.typeValue == 'month') {
        this.stepVal = [4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40]
      } else if (this.typeValue == 'year') {
        this.stepVal = [40, 50, 60, 70, 80, 90, 100, 120, 150, 200, 250, 300, 500]
      }
      this.minVal = Math.min.apply(null, this.stepVal)
      this.maxVal = this.stepVal.length - 1
    }
  }

  connect() {
    this.setMaxRange()
    this.setMinValPrice()
    this.handleSubmitAppearance()
    this.disableDotOnPriceTarget()
    this.changeImage()
  }

  /* ----- ACTION EVENTS ----- */

  disableDotOnPriceTarget() {
    this.priceTarget.addEventListener('keydown', e => {
      if (e.keyCode === 190 || e.keyCode === 110) {
        e.preventDefault();
      }
    })
  }

  /* ----- FUNCTIONS ----- */

  changeValuePrice() {
    this.priceTarget.value = this.stepVal[this.rangeTarget.value]
  }

  changeImage() {
    this.imageTarget.dataset.image = this.stepVal[this.rangeTarget.value]
  }

  handleSubmitAppearance() {
    if (this.priceTarget.value < this.minVal) {
      this.submitTarget.classList.add('main-button--disabled')
    } else {
      this.submitTarget.classList.remove('main-button--disabled')
    }
  }

  /* ----- GETTERS / SETTERS ----- */

  setMaxRange() {
    this.rangeTarget.setAttribute('max', this.maxVal)
  }

  setMinValPrice() {
    this.priceTarget.value = this.minVal
  }
}
