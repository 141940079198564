import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    target: String
  }

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  scrollToTarget() {
    if (this.targetValue) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          document.getElementById(this.targetValue).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top - document.querySelector('.page-header').getBoundingClientRect().height
      })
    }
  }
  /* ----- GETTERS / SETTERS ----- */
}
