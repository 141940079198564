import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    if (typeof hcaptcha !== 'undefined') {
      hcaptcha.render('h-captcha')
    }
  }

  disconnect() {
    if (typeof hcaptcha !== 'undefined') {
      hcaptcha.remove()
    }
  }
}
