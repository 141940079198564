import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = [ 'state' ]

  initialize() {
    this.element.parentElement.addEventListener('ajax:success', e => {
      this.element.classList.toggle(this.stateClass)
      if (this.element.dataset.favoritesList && !this.element.classList.contains(this.stateClass)) {
        this.element.closest('li').remove()
      }
    })
  }
}
