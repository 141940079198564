import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['block']

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.config = {
      isOpenClass: 'is-open',
      isCloseClass: 'is-close'
    }
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  toggleBlock(e) {
    e.currentTarget.classList.toggle(this.config.isCloseClass)
    if (this.blockTargets) {
      this.blockTargets.forEach(block => {
        block.classList.toggle(this.config.isOpenClass)

        if (block.classList.contains('collapsible')) {
          var isCollapsed = block.getAttribute('data-collapsed') === 'true'
          if(isCollapsed) {
            this.expandSection(block)
            block.setAttribute('data-collapsed', 'false')
          } else {
            this.collapseSection(block)
          }
        }
      })
    }
  }

  collapseSection(element) {
    var sectionHeight = element.scrollHeight;
    var elementTransition = element.style.transition;
    element.style.transition = ''
    requestAnimationFrame(function() {
      element.style.height = sectionHeight + 'px'
      element.style.transition = elementTransition
      requestAnimationFrame(function() {
        element.style.height = 0 + 'px'
      })
    })
    element.setAttribute('data-collapsed', 'true')
  }

  expandSection(element) {
    var sectionHeight = element.scrollHeight;
    element.style.height = sectionHeight + 'px'
    element.setAttribute('data-collapsed', 'false')
  }
}
