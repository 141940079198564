import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  submitCard() {
    this.buttonTarget.click()
  }

  /* ----- FUNCTIONS ----- */
}
