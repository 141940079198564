const language = document.documentElement.lang

const parseDOM = (data, type = 'text/html') => new DOMParser().parseFromString(data, type)

const serializeDOM = data => new XMLSerializer().serializeToString(data)

const toggleBodyLock = state => document.body.classList.toggle('is-locked', state)

const camelCase = str => str.toLowerCase().replace(/[-_\s.]+(.)?/g, (m, chr) => chr.toUpperCase())

const isObject = obj => Object.prototype.toString.call(obj) === '[object Object]'

export { camelCase, isObject, language, parseDOM, serializeDOM, toggleBodyLock }
