import MicroModal from 'micromodal'
import { Controller } from '@hotwired/stimulus'
import { toggleBodyLock } from 'scripts/utils/application'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    MicroModal.init(this.config)
    this.show()
  }

  disconnect() {
    this.element.remove()
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  show() {
    toggleBodyLock(true)
    MicroModal.show(this.element.id, this.config)
  }

  hide() {
    MicroModal.close(this.element.id)
  }

  /* ----- GETTERS / SETTERS ----- */

  get config() {
    return {
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      closeTrigger: 'data-micromodal-close',
      onClose: modal => {
        toggleBodyLock(false)
      }
    }
  }
}
