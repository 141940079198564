import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  handleToggleVisibility(event) {
    this.element.classList.toggle('is-visible')
    this.inputTarget.type = this.element.classList.contains('is-visible') ? 'text' : 'password'
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
